function Success() {
  return (
    <div className='container p-3'>
      <div className='text-center'>
        <p className='fw-bold fs-3'>Congratulations! Thank you!</p>
      </div>
    </div>
  )
}

export default Success
