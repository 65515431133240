import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Home from './home'
import Success from './success'
import Manage from './manage'
import AddCard from './card'
import Subscription from './subscription'
import Plans from './plans'
import NotFound from './notfound'
import Corporate from './corporate'
import PrivacyPolicy from './privacy-policy'
import QRCode from './qr'

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/subscription' element={<Subscription />} />
        <Route path='/manage' element={<Manage />} />
        <Route path='/plans' element={<Plans />} />
        <Route path='/card' element={<AddCard />} />
        <Route path='/corporate' element={<Corporate />} />
        <Route path='/success' element={<Success />} />
        <Route path='/notfound' element={<NotFound />} />
        <Route path='/privacy-policy' element={<PrivacyPolicy />} />
        <Route path='/qr' element={<QRCode />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App
