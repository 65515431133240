function NotFound() {
  return (
    <div className='container p-3'>
      <div className='text-center'>
        <p className='fw-bold fs-3'>Not Found Page</p>
      </div>
    </div>
  )
}

export default NotFound
