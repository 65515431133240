import axiosRoot, { AxiosInstance, Method } from 'axios'
import { BASE_URL } from '../constants/api'

class Api {
  private axios: AxiosInstance

  constructor() {
    this.axios = axiosRoot.create({
      baseURL: BASE_URL,
    })
    this.axios.interceptors.response.use((response) => {
      if (response.status < 300) {
        return response
      }
      return response
    })
  }

  /**
   * Set Access Token manually
   * @param data
   */
  setToken(token: string) {
    if (token) {
      this.axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
    }
  }

  async request(method: Method, url: string, data: any, params: any): Promise<any> {
    return await this.axios({ method: method, url: url, data: data, params: params })
      .then((response) => {
        return {
          status: response.status,
          data: response.data,
        }
      })
      .catch((error) => {
        console.log(`API error, ${url} - ${error}`)
        if (!error.response || error.response.status === 500) {
          return {
            status: 500,
            data: 'Something went wrong, Please try later',
          }
        }
        return {
          status: error.response.status,
          data:
            typeof error.response.data === 'string'
              ? error.response.data
              : error.response.data.message,
        }
      })
  }
}

export const ApiService = new Api()
