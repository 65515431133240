import './style.scss'

const ProgressBar = () => {
  return (
    <div className='progress-bar'>
      <div className='progress-bar-value'></div>
    </div>
  )
}

export default ProgressBar
