import { QRCodeSVG } from 'qrcode.react'
import { useEffect, useState } from 'react'

const UseWindowDimensions = () => {
  const hasWindow = typeof window !== 'undefined'

  function getWindowDimensions() {
    const width = hasWindow ? window.innerWidth : null
    const height = hasWindow ? window.innerHeight : null
    return {
      width,
      height,
    }
  }

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

  useEffect(() => {
    if (hasWindow) {
      const handleResize = () => {
        setWindowDimensions(getWindowDimensions())
      }

      window.addEventListener('resize', handleResize)
      return () => window.removeEventListener('resize', handleResize)
    }
  }, [hasWindow])

  return windowDimensions
}

function QRCode() {
  const qrURL = 'https://www.leadmasterstools.com/'
  const { width } = UseWindowDimensions()
  return (
    <div className='d-flex justify-content-center'>
      {width && (
        <QRCodeSVG
          value={qrURL}
          includeMargin
          level='M'
          size={width > 768 ? 640 : width * 0.8}
          imageSettings={{
            src: require('../../assets/qr-logo.png'),
            x: undefined,
            y: undefined,
            height: 30,
            width: 30,
            excavate: true,
          }}
        />
      )}
    </div>
  )
}

export default QRCode
