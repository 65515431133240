import { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ApiService } from '../../services/api.service'
import { Membership, Price } from '../../types'
import ProgressBar from '../../components/loading/progressBar'

function Plans() {
  const location = useLocation()
  const navigate = useNavigate()
  const [memberships, setMemberships] = useState<Price[]>([])
  const [currentPlan, setCurrentPlan] = useState<Membership>()
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    init()
  }, [location])

  const onPressSubscribe = useCallback(
    async (item: Price) => {
      if (currentPlan && currentPlan.subscription_status === 'active') {
        // update subscription
        setLoading(true)
        const body = { priceId: item.id }
        const response = await ApiService.request(
          'POST',
          '/memberships/update-subscription',
          body,
          null,
        )
        setLoading(false)
        if (response.status === 200) {
          navigate('/success')
        } 
      } else {
        setLoading(true)
        const data = { priceId: item.id }
        const response = await ApiService.request(
          'POST',
          '/memberships/create-subscription',
          data,
          {},
        )
        setLoading(false)
        if (response.status === 200) {
          navigate('/card', {
            state: {
              subscriptionId: response.data.subscriptionId,
              clientSecret: response.data.clientSecret,
              planAmount: item.unit_amount,
              token: location.state?.token,
            },
          })
        }
      }
    },
    [currentPlan, location],
  )

  const onPressCorporate = useCallback(() => {
    navigate('/corporate', {
      state: {
        token: location.state?.token,
      },
    })
  }, [location])

  // const onPressSinglePayment = useCallback(async() => {
  //   setLoading(true)
  //   const response = await ApiService.request(
  //     'POST',
  //     '/memberships/create-single-payment',
  //     {},
  //     {},
  //   )
  //   setLoading(false)
  //   if (response.status === 200) {
  //     navigate('/card', {
  //       state: {
  //         paymentIntentId: response.data.paymentIntentId,
  //         clientSecret: response.data.clientSecret,
  //         planAmount: response.data.planAmount,
  //         token: location.state?.token,
  //       },
  //     })
  //   }
  // }, [location])

  const init = async () => {
    const token = location.state?.token
    if (token) {
      ApiService.setToken(token)
      setLoading(true)
      const response = await ApiService.request('GET', '/memberships/subscriptions', null, null)
      if (response.status === 200) {
        const _currentPlan = response.data.currentPlan
        const _singlePayment = response.data.singlePayment
        setCurrentPlan(_currentPlan)
        setMemberships(
          response.data.prices
            .filter((e: Membership) => e.id !== response.data.currentPlan?.product_id)
            .sort((a: Price, b: Price) => a.unit_amount - b.unit_amount),
        )
        // Handle the exception of single-payment
        if (_currentPlan && _currentPlan.type === 'SINGLE' && _singlePayment ) {
          navigate('/manage', {
            state: {
              token: token,
            },
          })
          return
        }
        setLoading(false)
      } 
    } else {
      navigate('/notfound')
    }
  }

  if (loading) return <ProgressBar />

  return (
    <div className='container p-3'>
      <div className='text-center'>
        <p className='fw-bold fs-3'>Choose your Plan</p>
      </div>
      <div>
        {/* {
          !(currentPlan && currentPlan.type === 'STRIPE') && (
            <div className='card text-center mb-4'>
              <div className='card-header d-flex justify-content-between align-items-center'>
                <span className='fw-bold'>Single</span>
                <span className='fw-medium'>$10/one-time</span>
              </div>
              <div className='card-body text-start'>
                <div className='form-check form-check-inline '>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    id='inlineCheckbox1'
                    value='option1'
                    defaultChecked
                    disabled
                  />
                  <label className='form-check-label' htmlFor='inlineCheckbox1'>
                    {'1 document download'}
                  </label>
                </div>
              </div>
              <div className='card-footer text-body-secondary d-grid'>
                <button
                  className='btn btn-primary text-white'
                  type='button'
                  onClick={() => onPressSinglePayment()}
                >
                  Subscribe
                </button>
              </div>
            </div>
          )
        } */}
        
        {memberships.map((e) => (
          <div key={e.id} className='card text-center mb-4'>
            <div className='card-header d-flex justify-content-between align-items-center'>
              <span className='fw-bold'>{e.product?.name}</span>
              <span className='fw-medium'>{`$${e.unit_amount / 100}/m`}</span>
            </div>
            <div className='card-body text-start'>
              <div className='form-check form-check-inline '>
                <input
                  className='form-check-input'
                  type='checkbox'
                  id='inlineCheckbox1'
                  value='option1'
                  defaultChecked
                  disabled
                />
                <label
                  className='form-check-label'
                  htmlFor='inlineCheckbox1'
                >{`${e.metadata.Pdf === '-1' ? 'Unlimited' : e.metadata.Pdf} documents download`}</label>
              </div>
            </div>
            <div className='card-footer text-body-secondary d-grid'>
              <button
                className='btn btn-primary text-white'
                type='button'
                onClick={() => onPressSubscribe(e)}
              >
                {currentPlan && currentPlan.subscription_status === 'active'
                  ? 'Update subscription'
                  : 'Subscribe'}
              </button>
            </div>
          </div>
        ))}
        {!currentPlan && (
          <div className='card text-center mb-4'>
            <div className='card-header d-flex justify-content-between align-items-center'>
              <span className='fw-bold'>Corporate</span>
              <span className='fw-medium'></span>
            </div>
            <div className='card-body text-start'>
              <div className='form-check form-check-inline '>
                <input
                  className='form-check-input'
                  type='checkbox'
                  id='inlineCheckbox1'
                  value='option1'
                  defaultChecked
                  disabled
                />
                <label className='form-check-label' htmlFor='inlineCheckbox1'>
                  {'Unlimited documents download'}
                </label>
              </div>
            </div>
            <div className='card-footer text-body-secondary d-grid'>
              <button
                className='btn btn-primary text-white'
                type='button'
                onClick={() => onPressCorporate()}
              >
                Subscribe
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Plans
