import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ApiService } from '../../services/api.service'

function AddCard() {
  const location = useLocation()
  const navigate = useNavigate()
  const [subscriptionId, setSubscriptionId] = useState<string>()
  const [clientSecret, setClientSecret] = useState<string>()
  const [planAmount, setPlanAmount] = useState<number>()
  const [name, setName] = useState<string>('')
  const [message, setMessage] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)

  // Initialize an instance of stripe.
  const stripe = useStripe()
  const elements = useElements()

  useEffect(() => {
    init()
    setSubscriptionId(location.state.subscriptionId)
    setClientSecret(location.state.clientSecret)
    setPlanAmount(location.state.planAmount)
  }, [location])

  const init = () => {
    const token = location.state?.token
    if (token) {
      ApiService.setToken(token)
    } else {
      navigate('/notfound')
    }
  }

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return null
    }

    setLoading(true)
    const cardElement = elements.getElement(CardElement)
    try {
      // Use card Element to tokenize payment details
      const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret!, {
        payment_method: {
          card: cardElement!,
          billing_details: {
            name: name,
          },
        },
        setup_future_usage: 'off_session',
      })

      if (error) {
        // show error and collect new card details.
        setLoading(false)
        setMessage(error.message || '')
        return
      }

      if (paymentIntent && paymentIntent.status === 'succeeded') {
        let response
        if (subscriptionId) {
          // Update the default payment method on the subscription.
          const data = { subscriptionId: subscriptionId, paymentIntentId: paymentIntent.id }
          response = await ApiService.request(
            'POST',
            '/memberships/update-subscription-payment',
            data,
            null,
          )
          setLoading(false)
        } else {
          const data = { paymentIntentId: paymentIntent.id }
          response = await ApiService.request(
            'POST',
            '/memberships/update-single-payment',
            data,
            null,
          )
          setLoading(false)
        }
       
        if (response.status === 200) {
          navigate('/success')
        } else {
          setMessage(response.data)
        }
      } else {
        setLoading(false)
        // Handle other payment intent status (e.g., 'requires_action')
        // You may need to handle additional actions, such as 3D Secure authentication
      }
    } catch (err) {
      console.log(err)
    }
  }

  const onBack = () => {
    navigate(`/subscription?token=${location.state?.token}`)
  }

  if (!clientSecret || !planAmount) return null

  return (
    <div className='container p-3'>
      <div className='text-center'>
        <p className='fw-bold fs-3'>Add Payment method</p>
      </div>
      <form>
        <div className='mb-3'>
          <input
            type='text'
            className='form-control'
            id='fullName'
            placeholder='Full Name'
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className='mb-2'>
          <span className='fw-medium'>Enter your card info</span>
        </div>
        <CardElement />
        <div className='card-footer text-body-secondary d-grid mt-4'>
          <button
            className='btn btn-primary text-white'
            type='button'
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading ? (
              <span className='spinner-border spinner-border-sm' aria-hidden='true'></span>
            ) : (
              `Pay $${planAmount / 100}`
            )}
          </button>
        </div>
        <div className='text-body-secondary d-grid mt-3'>
          <button className='btn btn-primary text-white' type='button' onClick={onBack}>
            Back
          </button>
        </div>
        {message && (
          <div className='mt-2 text-center'>
            <span className='fw-medium text-primary'>{message}</span>
          </div>
        )}
      </form>
    </div>
  )
}

export default AddCard
