import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ApiService } from '../../services/api.service'
import ProgressBar from '../../components/loading/progressBar'

function Subscription() {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    init()
  }, [searchParams])

  const init = useCallback(async () => {
    const token = searchParams.get('token')
    if (!token) {
      navigate('/notfound')
    } else {
      ApiService.setToken(token)
      setLoading(true)
      const response = await ApiService.request('GET', '/memberships/subscriptions', null, null)
      setLoading(false)
      if (response.status === 200) {
        const currentPlan = response.data.currentPlan
        const singlePayment = response.data.singlePayment
        if (!currentPlan) {
          navigate('/plans', {
            state: {
              token: token,
            },
          })
          return
        }
        if ((currentPlan.type === 'CODE') || (currentPlan.type === 'STRIPE' && currentPlan.subscription_id)) {
          navigate('/manage', {
            state: {
              token: token,
            },
          })
          return
        }
        if (currentPlan.type === 'SINGLE' && singlePayment) {
          navigate('/manage', {
            state: {
              token: token,
            },
          })
          return
        }
        navigate('/plans', {
          state: {
            token: token,
          },
        })
      } else {
        navigate('/notfound')
      }
    }
  }, [searchParams])

  if (loading) return <ProgressBar />

  return <div></div>
}

export default Subscription
