import { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ApiService } from '../../services/api.service'

function Corporate() {
  const location = useLocation()
  const navigate = useNavigate()
  const [code, setCode] = useState<string>('')
  const [message, setMessage] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    init()
  }, [location])

  const init = () => {
    const token = location.state?.token
    if (token) {
      ApiService.setToken(token)
    } else {
      navigate('/notfound')
    }
  }

  const handleSubmit = useCallback(async () => {
    if (!code) {
      setMessage('Empty Code')
      return
    }
    setLoading(true)
    const data = { code: code }
    const response = await ApiService.request(
      'POST',
      '/memberships/create-corporate-subscription',
      data,
      null,
    )
    setLoading(false)
    if (response.status === 200) {
      navigate('/success')
    } else {
      setMessage(response.data)
    }
  }, [code])

  const onBack = () => {
    navigate(`/subscription?token=${location.state?.token}`)
  }

  return (
    <div className='container p-3'>
      <div className='text-center'>
        <p className='fw-bold fs-3'>Corporate</p>
      </div>
      <form>
        <div className='mb-3'>
          <div className='mb-2'>
            <span className='fw-medium'>Enter the code</span>
          </div>
          <input
            type='text'
            className='form-control'
            id='code'
            placeholder='Code'
            value={code}
            onChange={(e) => setCode(e.target.value)}
          />
        </div>
        <div className='card-footer text-body-secondary d-grid mt-4'>
          <button
            className='btn btn-primary text-white'
            type='button'
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading ? (
              <span className='spinner-border spinner-border-sm' aria-hidden='true'></span>
            ) : (
              'Continue'
            )}
          </button>
        </div>
        <div className='text-body-secondary d-grid mt-3'>
          <button className='btn btn-primary text-white' type='button' onClick={onBack}>
            Back
          </button>
        </div>
        {message && (
          <div className='mt-2 text-center'>
            <span className='fw-medium text-primary'>{message}</span>
          </div>
        )}
      </form>
    </div>
  )
}

export default Corporate
