import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './pages/App'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import './scss/custom.scss'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import { ApiService } from './services/api.service'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

ApiService.request('GET', '/memberships/config', {}, {})
  .then((response) => {
    if (response.status === 200) {
      const stripePromise = loadStripe(response.data.publishableKey)

      root.render(
        <React.StrictMode>
          <Elements stripe={stripePromise}>
            <App />
          </Elements>
        </React.StrictMode>,
      )
    }
  })
  .catch((error) => {
    console.log(error)
  })
