import { QRCodeSVG } from 'qrcode.react'

function Home() {
  const googleStoreUrl = 'https://play.google.com/store/apps/details?id=com.leadmasterstools&hl=en_US&gl=US'
  const appStoreUrl = 'https://apps.apple.com/us/app/lead-masters/id6470849379'

  return (
    <div className='container p-3'>
      <div className='text-center mt-4'>
        <img
          src={require('../../assets/lead_masters_logo.png')}
          style={{ width: 275, height: 200, objectFit: 'contain' }}
          alt='logo'
        />
        <div>
          <QRCodeSVG
            value={googleStoreUrl}
            includeMargin
            level='M'
            size={200}
            imageSettings={{
              src: require('../../assets/qr-logo.png'),
              x: undefined,
              y: undefined,
              height: 30,
              width: 30,
              excavate: true,
            }}
          />
        </div>
        <p className='text-secondary mt-4'>PDF generator for RRP leadwork</p>
        <div className='d-flex mt-4 flex-column align-items-center'>
          <a
            href={googleStoreUrl}
            target='_blank'
            rel='noreferrer'
          >
            <img
              src={require('../../assets/google_play.jpg')}
              style={{ width: 200, height: 70, objectFit: 'contain' }}
              alt='google-logo'
            />
          </a>
          <a
            href={appStoreUrl}
            target='_blank'
            rel='noreferrer'
          >
            <img
              src={require('../../assets/app_store.png')}
              style={{ width: 200, height: 60, objectFit: 'contain' }}
              alt='appstore-logo'
            />
          </a>
        </div>
      </div>
    </div>
  )
}

export default Home
