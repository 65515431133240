import { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ApiService } from '../../services/api.service'
import ProgressBar from '../../components/loading/progressBar'
import { Membership, SinglePayment, Subscription } from '../../types'
import { PaymentIntent } from '@stripe/stripe-js'

function Manage() {
  const location = useLocation()
  const navigate = useNavigate()
  const [loading, setLoading] = useState<boolean>(false)
  const [canceling, setCanceling] = useState<boolean>(false)
  const [currentPlan, setCurrentPlan] = useState<Membership>()
  const [subscription, setSubscription] = useState<Subscription>()
  const [singlePayment, setSinglePayment] = useState<SinglePayment>()
  const [paymentIntent, setPaymentIntent] = useState<PaymentIntent>()

  useEffect(() => {
    init()
  }, [])

  const init = useCallback(async () => {
    const token = location.state?.token
    if (!token) {
      navigate('/notfound')
      return
    }
    ApiService.setToken(token)
    setLoading(true)
    const response = await ApiService.request('GET', '/memberships/my-subscription', null, null)
    setLoading(false)
    if (response.status === 200) {
      setCurrentPlan(response.data.currentPlan)
      setSubscription(response.data.subscription)
      setPaymentIntent(response.data.paymentIntent)
      setSinglePayment(response.data?.singlePayment)
    } 
  }, [location])

  const cancelPlan = async () => {
    setCanceling(true)
    const response = await ApiService.request(
      'POST',
      '/memberships/cancel-subscription',
      null,
      null,
    )
    setCanceling(false)
    if (response.status === 200) {
      navigate('/success')
    }
  }

  const updatePayment = useCallback(() => {
    if (currentPlan?.type === 'STRIPE') {
      if (!subscription || !paymentIntent) return
      navigate('/card', {
        state: {
          subscriptionId: subscription.id,
          clientSecret: paymentIntent?.client_secret,
          planAmount: subscription.plan.amount,
          token: location.state?.token,
        },
      })
    }

    if (currentPlan?.type === 'SINGLE') {
      if (!paymentIntent) return
      navigate('/card', {
        state: {
          paymentIntentId: paymentIntent?.id,
          clientSecret: paymentIntent?.client_secret,
          planAmount: paymentIntent?.amount,
          token: location.state?.token,
        },
      })
    }
    
  }, [currentPlan, subscription, paymentIntent, location])

  // const updatePlan = useCallback(() => {
  //   if (!subscription) return
  //   navigate('/plans', {
  //     state: {
  //       token: location.state?.token,
  //     },
  //   })
  // }, [subscription, paymentIntent, location])

  const getStatus = useCallback(() => {
    if (currentPlan?.type === 'STRIPE') {
      return subscription?.status === 'active' ? 'Active' : 'Inactive'
    } else if (currentPlan?.type === 'CODE') {
      return currentPlan.subscription_status
    } else if (currentPlan?.type === 'SINGLE') {
      return singlePayment?.payment_intent_status
    } 
    return '-'
  }, [currentPlan, subscription, singlePayment])

  const getPlanType = useCallback(() => {
    if (currentPlan?.type === 'STRIPE') {
      return subscription?.plan?.amount ? `$${subscription?.plan?.amount / 100}/m` : '' 
    } else if (currentPlan?.type === 'SINGLE'){
      return '$10'
    }
    return ''
  }, [currentPlan, subscription, singlePayment])

  if (loading) return <ProgressBar />

  return (
    <div className='container p-3'>
      <div className='text-center'>
        <p className='fw-bold fs-3'>Manage Subscription</p>
      </div>
      <div>
        {
          currentPlan?.type !== 'CODE' && (
            <div className='d-flex'>
              <span className='fw-bold'>Plan Type</span>
              <span className='mx-2'>
                {' '}
                {getPlanType()}
              </span>
            </div>
          )
        }
        <div className='d-flex'>
          <span className='fw-bold'>Status</span>
          <span className='mx-2'>{getStatus()}</span>
        </div>
        {
          currentPlan?.type === 'STRIPE' && (
            <div className='d-flex'>
              <span className='fw-bold'>Member since</span>
              <span className='mx-2'>
                {subscription?.current_period_start
                  ? new Date(subscription?.current_period_start * 1000).toLocaleDateString('en-US')
                  : ''}
              </span>
            </div>
          )
        }
        {
          currentPlan?.type === 'STRIPE' && (
            <>
              {subscription?.status === 'active' ? (
                <></>
                // <div className='text-body-secondary d-grid my-3'>
                //   <button className='btn btn-primary text-white' type='button' onClick={updatePlan}>
                //     Update Plan
                //   </button>
                // </div>
              ) : (
                <div className='text-body-secondary d-grid my-3'>
                  <button className='btn btn-primary text-white' type='button' onClick={updatePayment}>
                    Update Payment Method
                  </button>
                </div>
              )}
            </>
          )
        }
        {
          currentPlan?.type === 'SINGLE' &&  singlePayment?.payment_intent_status !== 'active' && (
            <>
              <div className='text-body-secondary d-grid my-3'>
                <button className='btn btn-primary text-white' type='button' onClick={updatePayment}>
                  Update Payment Method
                </button>
              </div>
            </>
          )
        }
        {
          !(currentPlan?.type === 'SINGLE' && singlePayment?.payment_intent_status === 'active') && (
            <div className='text-body-secondary d-grid my-3'>
              <button
                className='btn btn-primary text-white'
                type='button'
                disabled={canceling}
                onClick={cancelPlan}
              >
                {canceling ? (
                  <span className='spinner-border spinner-border-sm' aria-hidden='true'></span>
                ) : (
                  'Cancel Plan'
                )}
              </button>
            </div>
          )
        }
      </div>
    </div>
  )
}

export default Manage
